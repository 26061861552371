<template>
  <div class="student-grievance">
    <!--Banner Wrap Start-->
    <div class="iner_banner">
      <div class="container">
        <h5>Student Grievance</h5>
      </div>
    </div>
    <!--Banner Wrap End-->
    <div class="container">
      <div class="iqoniq2-leave-message">
        <form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="iq-input">
                <input type="text" id="studentName" required v-model="form.studentName" placeholder="Enter student name...">
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="iq-input">
                <input type="text" id="studentAcademicYear" required v-model="form.studentAcademicYear" placeholder="Enter academic year...">
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="iq-input">
                <select id="type" v-model="form.type" required placeholder="Select type...">
                  <option :key="i" v-for="(option, i) in typeOptions" :value="option">{{ option }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="iq-input">
                <input type="email" id="email" required v-model="form.email" placeholder="Enter email...">
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="iq-input">
                <input type="text" id="phone" required v-model="form.phone" placeholder="Enter phone number...">
              </div>
            </div>
            <div class="col-md-12">
              <div class="iq-input">
                <textarea id="message" v-model="form.message" required placeholder="Enter message..."></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <input class="iq_link_1" type="submit" value="submit">
            </div>
          </div>
        </form>
        <div :class="{'text-success': formSubmitted, 'text-danger': !formSubmitted}">
          {{ formMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'StudentGrievance',
  data () {
    return {
      form: {
        studentName: '',
        studentAcademicYear: '',
        type: 'ANTI RAGGING',
        email: '',
        phone: '',
        message: ''
      },
      typeOptions: ['ANTI RAGGING', 'GRIEVANCE REDRESSAL', 'SC/ST GRIEVANCE', 'WOMEN GRIEVANCE REDRESSAL'],
      formSubmitted: false,
      formMessage: ''
    }
  },
  methods: {
    ...mapActions(['submitFormData']),
    async submitForm () {
      try {
        const { success, message } = await this.submitFormData(this.form)
        this.formSubmitted = success
        this.formMessage = message
        if (success) {
          this.form = {
            studentName: '',
            studentAcademicYear: '',
            type: '',
            email: '',
            phone: '',
            message: ''
          }
        }
      } catch (error) {
        console.error(error)
        this.formMessage = error.message
        this.formSubmitted = false
      }
    }
  }
}
</script>

<style scoped>
.iqoniq2-leave-message{
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
